import { Keyboard, Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { ProjectsSliderProps } from "./ProjectSliderProps";
import { projectStrings } from "../../../model/types";
import { useContext } from "react";
import { LanguageContext, LanguageContextType } from "../../../model/LanguageContext";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Button } from "@mui/material";


export default function ProjectSliderXL({ isProjectClicked, setIsProjectClicked, handleProjectClick }: ProjectsSliderProps) {
    const { language } = useContext<LanguageContextType>(LanguageContext);

    return (
        <Swiper
            /* grabCursor={true} */
            slidesPerView={'auto'}
            spaceBetween={30}
            dir="rtl"
            scrollbar={{
                hide: false,
            }}
            keyboard={{
                enabled: true,
            }}
            slideToClickedSlide
            /* navigation={true} */
            onActiveIndexChange={() => setIsProjectClicked([false, false, false])}
            modules={[Keyboard, Scrollbar]}
            className="mySwiper position-relative"
        >
            {projectStrings.map((project, index) => (
                <SwiperSlide className={`${isProjectClicked[index] ? 'project-active' : ''}`} key={index} onClick={() => handleProjectClick(index)}>

                    <div className="box-up flex-column">
                        <h2>{project.title[language]}</h2>
                        <p className="col-lg-6">
                            {project.description[language]}
                        </p>
                        
                    </div>

                    <div className="d-flex justify-content-center align-items-center col-12 position-relative">

                        <p className="project-title col-12">
                            {project.stringName}
                        </p>

                    </div>
                    <img
                        className="project-img"
                        src={`${project.pictureURL}_2.webp?tr=w-1920`}

                        loading="lazy"
                    />
                    <div className="swiper-lazy-preloader"></div>
                    <div className="box-down column-gap-2">
                        <Button className="project-btn" >
                            <a aria-label='' className='social-link' href={project.siteURL} target="_blank" rel='noreferrer'>
                                Site
                            </a>
                            
                        </Button>
                        <Button className="project-btn">
                        <a aria-label='' className='social-link' href={project.codeURL} target="_blank" rel='noreferrer'>
                                Code
                            </a>
                        </Button>
                    </div>
                </SwiperSlide>
            ))}


        </Swiper>
    )
}