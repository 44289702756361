import { EffectCreative, Keyboard, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { ProjectsSliderProps } from "./ProjectSliderProps";
import { projectStrings } from "../../../model/types";
import { useContext } from "react";
import { LanguageContext, LanguageContextType } from "../../../model/LanguageContext";
import { Button } from "@mui/material";

export default function ProjectSliderSM({ isProjectClicked, setIsProjectClicked, handleProjectClick }: ProjectsSliderProps) {
    const { language } = useContext<LanguageContextType>(LanguageContext);
    return (


        <Swiper
            grabCursor={true}
            effect={'creative'}
            keyboard={{
                enabled: true,
            }}
            pagination={{
                enabled: true,

            }}
            creativeEffect={{
                prev: {
                    shadow: true,
                    translate: [0, 0, -400],
                },
                next: {
                    translate: ['100%', 0, 0],
                },
            }}
            modules={[EffectCreative, Keyboard, Pagination]}
            onActiveIndexChange={() => setIsProjectClicked([false, false, false])}
            className="mySwiper"
        >
            {projectStrings.map((project, index) => (
                <SwiperSlide className={`${isProjectClicked[index] ? 'project-active' : ''}`} key={index} onClick={() => handleProjectClick(index)}>
                    <div className="box-up flex-column">
                        <h2>{project.title[language]}</h2>
                        <p className="col-10 text-center">
                            {project.description[language]}
                        </p>

                    </div>
                    <p className="project-title col-12">
                        {project.stringName}
                    </p>

                    <img
                        className="project-img"
                        src={`${project.pictureURL}_1.webp?tr=w-1900`}

                        loading="lazy"
                    />
                    <div className="swiper-lazy-preloader"></div>
                    <div className="box-down column-gap-2">
                        <Button className="project-btn" >
                            <a aria-label='' className='social-link' href={project.siteURL} target="_blank" rel='noreferrer'>
                                Site
                            </a>
                            
                        </Button>
                        <Button className="project-btn">
                        <a aria-label='' className='social-link' href={project.codeURL} target="_blank" rel='noreferrer'>
                                Code
                            </a>
                        </Button>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    )
    /* <Swiper
            grabCursor={true}
            effect={'creative'}
            keyboard={{
                enabled: true,
            }}
            pagination={{
                enabled: true,
               
              }}
            creativeEffect={{
                prev: {
                    shadow: true,
                    translate: [0, 0, -400],
                },
                next: {
                    translate: ['100%', 0, 0],
                },
            }}
            modules={[EffectCreative, Keyboard, Pagination]}
            onActiveIndexChange={() => setIsProjectClicked([false, false, false])}
            className="mySwiper"
        >
            {projectStrings.map((project, index) => (
                <SwiperSlide className={`${isProjectClicked[index] ? 'project-active' : ''}`} key={index} onClick={() => handleProjectClick(index)}>
                    <div className="box-up"></div>
                    <p className="project-title col-12">
                        {project.title[language]}
                    </p>

                    <img
                        className="project-img"
                        src={`${project.pictureURL}?tr=w-1900`}

                        loading="lazy"
                    />
                    <div className="swiper-lazy-preloader"></div>
                    <div className="box-down"></div>
                </SwiperSlide>
            ))}
        </Swiper> */
}